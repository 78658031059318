* {
    margin: 0;
    padding: 0;
  
  }
  
  .header_nav {
    display: flex;
    justify-content: center;
    background-color: #4281ab;
  }

  .menu-items {
    color: #ffffff;
    text-decoration: none;
    padding: 20px 50px 20px 50px;
    border: 1px solid #a5c3d6;
    font-family: "Roboto", sans-serif;
    }
    
    .menu-items:hover {
      background-color: #a5c3d6;
    }

  /* menu on desktop */
  .desktop-nav .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    
  }
  
  .desktop-nav .menu-items {
    position: relative;
    font-size: 16px;
  }
  
  .desktop-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }
  
  .desktop-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  .desktop-nav button span {
    margin-left: 3px;
  }
  
  .desktop-nav .menu-items > a,
  .desktop-nav .menu-items button {
    text-align: left;
  }
  
  .desktop-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .desktop-nav .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    z-index: 9999;
    min-width: 13rem;
    list-style: none;
    background-color: #4281ab;
    border-radius: 0.5rem;
    display: none;
  }
  
  .desktop-nav .dropdown.show {
    display: block;
  }
  
  .desktop-nav .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .desktop-nav .dropdown .menu-items {
    padding: 10px;
  }
  
  .mobile-nav {
    display: none;
  }
  
  /* menu on mobile */
  @media screen and (max-width: 960px) {
  /*  .nav-area {
      justify-content: space-between;
    }
  */
    .desktop-nav {
      display: none;
    }
  
    .mobile-nav {
      display: block;
      padding: 10px;
      position: relative;
    }

    .mobile-nav .mobile-nav__menu-button {
      font-size: inherit;
      border: none;
      background-color: transparent;
      cursor: pointer;
      position: relative;
      color: #ffffff;
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      width: 300px;
      text-align: end;
    }

    .mobile-nav .menus {
      list-style: none;
      position: absolute;
      left: 0;
      z-index: 9999;
      min-width: 12rem;
      padding: 0;
      background-color: #4281ab;
      border-radius: 0.5rem;
    }
    .mobile-nav .menu-items {
      padding: 0;
      background-color: #4281ab;
      border: none;
      text-transform: uppercase;
    }

    .mobile-nav .menu-items a {
      display: block;
      font-size: inherit;
      color: inherit;
      text-decoration: none;
      padding-left: 0;
      border: 1px #a5c3d6 solid;
    }
  
    .mobile-nav .menu-items button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit;
      font-size: inherit;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  
    .mobile-nav .menu-items > a,
    .mobile-nav .menu-items button {
      text-align: left;
      padding: 5px 0 5px 5px;
      
    }

    .mobile-nav .menu-items a:hover,
    .mobile-nav .menu-items button:hover {
      background-color: #4281ab;
    }
  #arrow-button {
    border-left: 2px solid #a5c3d6;
    padding: 5px;
    margin: 0;
    width: 40px;
  }
    .mobile-nav .arrow::after {
      content: "\203A";
      font-size: 24px;
    }
    .mobile-nav .arrow-close::after {
      content: "\2039";
      font-size: 24px;
    }
    #list {
      display: flex;
      align-items: center;     
    }
    #list span {
      text-transform: uppercase;
    }
  #mobile_submenu_open_close {
    display: grid;
    grid-template-columns: 10fr 1fr;
   padding-left: 5px;
    border: 1px #a5c3d6 solid;
  }

  .mobile-nav .dropdown {
    margin-left: 0;
    display: none;
    }
  
    .mobile-nav .dropdown.show {
      display: block;
      border: none;
 
    }

    .mobile-nav .dropdown.show li{
      display: block;
      text-transform: none;   
      padding-left: 10px;
      background-color: #a5c3d6;
    }

  }
  
 
