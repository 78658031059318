.accordion_btn {
    display: inline-block;
    position: relative;
    background-color: transparent;
    color: #1a9e3b;
    border-radius: 15px;
    padding: 10px;
    text-transform: uppercase;
    border: 3px solid #1a9e3b;
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
    text-decoration: none;
    font-size: 16px; 
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin: 5px;
}

.accordion_btn:hover {
    font-weight: bold;
    text-decoration: underline;
    background-color: #0d7727;
    color: #ffffff;
}

.accordion_text {
    font-family: "Roboto", sans-serif; 
    color: #666666;
    margin-bottom: 10px;;

}
.accordion_panel {
    margin-left: 10%;
    margin-right: 10%;
}

.accordion_title {
    padding: 20px;
    font-family: "Russo One", sans-serif;
    color: #666666;
    font-size: 24px;
    width: 100%;
    text-align: left;
    border: none;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.accordion_title:hover {
    border-left: #efe74a 7px solid;
    color: #1a9e3b;
}

.accordion_content {
margin: 20px;
}


.accordion_content img {
    width: 300px; 
    transition: width 0.3s ease;
    cursor: zoom-in;
  }
  
  .accordion_content img.zoomed {
    width: 100%; 
    cursor: zoom-out;
  }

  @media screen and (max-width: 420px) {
    .accordion_title {
        font-size: 16px;
    }

  }