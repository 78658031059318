

.main_news_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    max-width: 70%;
    height: 150px;
    padding: 10px;
    margin: 20px 20px 20px 12%;
    text-align: center;
    border: none;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif;
    color: #666666;
    cursor: pointer;
}
  
.main_news_box:hover {
    background-color: rgb(239, 231, 74, 0.7);
  }


.main_news_box a {
    text-decoration: none;
    color: #1a9e3b;
}

.main_news_box a:hover {
    text-decoration: underline;
    font-weight: bold;
}

/* Стили для индикаторов */

  .custom-dot-list-style li button {
    width: 10px; 
    height: 10px; 
  }


  @media screen and (max-width: 377px) {
    .main_news_box h3 {
      font-size: 14px;
    }

  }
