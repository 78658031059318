
.emergency_phones {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
  .phone_numbers {
   display: flex;
   align-items: center;
  }
  
  .phone_text {
    margin-right: 5px;
    margin-left: 5px;
  }

  .hr_contacts {
    margin: 20px 10% 100px 10%;
    background-color: #1a9e3b;
    height: 2px;
  }

  .header_contact {
    display: flex;
    font-family: "Roboto", sans-serif;
    color: #666666;
    font-size: 36px;
    margin-left: 10%;
  }
  
  .box_contacts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 10%;
  margin-right: 10%;
  }
  
  .box5 {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  
  .info_contacts {
    display: flex;
    margin: 10px;
    align-items: center;
    }
  
  .heading_contacts {
    font-family: "Roboto", sans-serif;
    color: #666666;
    font-size: 18px;
    text-align: center;
  }
  
  .text_contacts {
    font-family: "Roboto", sans-serif;
    color: #666666;
    margin: 0;
  }
  
  .phone_contacts {
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    color: #666666;
    font-weight: bold;
   
  }
  
  .cont_useful_phones {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .useful_phones {
    display: flex;
    padding: 50px;
  }

  .cont_center_requisites {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .actual_requisites {
    font-family: "Roboto", sans-serif;
    color: #666666;
    font-size: 18px;
  }
  .cont_requisites {
    margin-top: 50px;
    padding-top: 70px;
    padding-bottom: 70px;
    margin-bottom: 100px;
    background-color: rgb(239, 231, 74, 0.9);
    clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%); /* Определение трапеции с помощью polygon */
  }
  
  .requisites_btn {
    background-color: #1a9e3b;
    color: #ffffff;
    border-radius: 15px;
    padding: 15px;
    text-transform: uppercase;
    border: none;
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
    margin-top: 10px; 
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);   
    font-size: 14px; 
  }

.requisites_btn:hover {
    font-weight: bold;
    text-decoration: underline;
    background-color: #0d7727;
}

.email_link {
  color: #666666; 
}
  
  /* contactForm */
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
   
  
  }
  .form_name {
    display: flex;
    align-items: center;
  }
  
  #name, #email {
    padding: 5px;
    margin-bottom: 5px;
    font-family: "Roboto", sans-serif; 
    width: 50%;
    align-self: center;
  }
  
  #message {
    padding: 5px;
    height: 100px; 
    font-family: "Roboto", sans-serif; 
    width: 50%;
    align-self: center;
  }
  .contact_btn {
    background-color: #1a9e3b;
    color: #ffffff;
    border-radius: 15px;
    padding: 15px;
    text-transform: uppercase;
    border: none;
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
    width: 150px;
    align-self: center;
    margin: 10px;
  }
  
  .contact_btn:hover {
    background-color: #0d7727;
  }
  
  .after_sending {
    text-align: center;
    font-size: 24px;
    font-family: "Roboto", sans-serif; 
    color: #0d7727;
    margin-top: 100px;
    font-weight: bold;
  }


  @media screen and (max-width: 854px) {
    .cont_useful_phones {
      grid-template-columns: 1fr 1fr;
    }
    .useful_phones {
      padding: 20px;
    }
    .info_contacts {
      margin: 5px;
      }
  }

  @media screen and (max-width: 540px) {
    .box_contacts {
      grid-template-columns: 1fr;
      }

    .cont_useful_phones {
      grid-template-columns: 1fr;
    }

    .phone_numbers {
      flex-direction: column;
      margin-top: 10px;
     }

     .phone_numbers img {
      width: 50px;
     }

     .header_contact {
      font-size: 28px;
     }

     .actual_requisites {
      text-align: center;
      margin-left: 10%;
      margin-right: 10%;
     }

     .info_contacts img {
      width: 50px;
      height: 50px;
     }
  }