.cont_header_news {
    background-color: #4281ab;
    background-image: url('../houses.png');
    background-position: right;
    background-size: 28%;
    background-repeat: no-repeat;
    padding: 100px;
    border: 1px solid #ffffff;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
}

.main_header_news {
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    margin-left: 10%;
    font-size: 28px; 
}

.span_header_news {
    font-weight: 100;
    font-size: 16px;
}

.news_container {
display: grid;
grid-template-columns: repeat(3, 1fr);
margin: 50px 10% 50px 10%;
}

.news_box {
    padding: 20px;
    margin: 20px;
    text-align: center;
    border: none;
    width: 300px;
    height: 200px;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif;
    color: #666666;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.news_box:nth-child(3n+1):hover {
    background-color: rgb(134, 199, 241, 0.7);
  }
  
  .news_box:nth-child(3n+2):hover {
    background-color: rgb(239, 231, 74, 0.7);
  }
  
  .news_box:nth-child(3n):hover {
    background-color: rgb(26, 158, 59, 0.5);
  }
.news_btn {
    background-color: transparent;
    color: #1a9e3b;
    border-radius: 15px;
    padding: 10px;
    text-transform: uppercase;
    border: 3px solid #1a9e3b;
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
    text-decoration: none;
    font-size: 14px; 
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    
}

.news_btn:hover {
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
    background-color: #0d7727;
}

/* новости на отдельных страницах */

.news_header {
color: #1a9e3b;
font-family: "Russo One", sans-serif;
margin-top: 50px;
text-align: center;
}

.news_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Roboto", sans-serif;
    color: #666666;
    margin: 50px;
}

.news_content p {
    padding: 20px;
    text-align: center;
}

.news_content a {
color: #666666;
font-family: "Roboto", sans-serif;
text-align: center;
}

.news_content a:hover {
    font-weight: bold;
    color:#1a9e3b;
    }
.system {
    margin: 10px 50px;
}
/* кнопки навигации по новостям */
.navigation_news {
    display: flex;
    justify-content: space-between;
    

}

.navigation_news_btn {
    background-color: transparent;
    color: #1a9e3b;
    border-radius: 15px;
    padding: 10px;
    text-transform: uppercase;
    border: 3px solid #1a9e3b;
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
    text-decoration: none;
    font-size: 14px; 
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin: 10px;
}

.navigation_news_btn:hover {
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
    background-color: #0d7727;
}

@media screen and (max-width: 1280px) {
 
    .news_box {
        padding: 10px;
        width: 80%;
        height: 200px;
    }
}

@media screen and (max-width: 1024px) {
    .cont_header_news {
        background-size: 35%;
        }
  }

@media screen and (max-width: 855px) {
    .cont_header_news {
        background-size: 40%;
        }
    .news_container {
        grid-template-columns: 1fr 1fr;
        }
    .news_box {
        padding: 10px;
        width: 80%;
        height: 200px;
    }
    .news_content img {
        width: 100%;
    }
}

@media screen and (max-width: 766px) {
    .news_container {
        grid-template-columns: 1fr;
        }
    }

  @media screen and (max-width: 560px) {
    .cont_header_news {
    background-size: 45%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    padding: 60px;
    }

    .main_header_news {
        margin-left: 0;
        font-size: 20px;
    }

    .span_header_news {
        font-size: 14px;
    }

    .news_header {
        font-size: 20px;
        }

    .navigation_news {
            justify-content: center;
        }
        
    .large_screen {
        display: none;
    }      
      .news_content {
            margin: 10px;
        }
    }

  @media screen and (max-width: 380px) {
    .news_header {
        font-size: 16px;
        }

        
  }

  @media screen and (max-width: 280px) {
    .cont_header_news {
        background-size: 55%;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%); 
        padding: 40px;
        }

        .news_content img {
            width: 120%;
        }
  }







