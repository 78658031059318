.cont_header_sanitar {
    background-color: #4281ab;
    background-image: url('../houses.png');
    background-position: right;
    background-size: 28%;
    background-repeat: no-repeat;
    padding: 100px;
    border: 1px solid #ffffff;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
    margin-bottom: 50px;
}

.main_header_sanitar {
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    margin-left: 10%;
    font-size: 28px; 
}

.span_header_sanitar {
    font-weight: 100;
    font-size: 16px;
}
.sanitar {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-bottom: 20px;
    padding: 10px;
    font-family: "Roboto", sans-serif;
    color: #666666;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    width: 50%;
}

.sanitar div {
  display: flex;
}

.sanitar h4 {
    margin-right: 30px;
    width: 30%;
}

.sanitar:hover {
    border-left: 7px #efe74a solid;
}

.sanitar_cont {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 100px;
  }
  
  .sanitar_box {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr;
    width: 100%;
    border-bottom: 1px solid #ccc; 
    cursor: pointer;
    padding: 10px;
  }
  
  .sanitar_box p,
  .sanitar_box h4 {
    padding: 10px;
    font-family: "Roboto", sans-serif;
    color: #666666;
    font-size: 14px;
  }

  .sanitar_box:nth-child(3n+1):hover {
    background-color: rgb(134, 199, 241, 0.7);
  }
  
  .sanitar_box:nth-child(3n+2):hover {
    background-color: rgb(239, 231, 74, 0.7);
  }
  
  .sanitar_box:nth-child(3n):hover {
    background-color: rgb(26, 158, 59, 0.5);
  }



  @media screen and (max-width: 1024px) {
    .cont_header_sanitar {
        background-size: 35%;
        }
        .sanitar {
          width: 80%;
      }
  }

  @media screen and (max-width: 855px) {
    .cont_header_sanitar {
        background-size: 40%;
        }
}

@media screen and (max-width: 560px) {
  .cont_header_sanitar {
  background-size: 45%;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
  padding: 60px;
  }

  .main_header_sanitar {
      margin-left: 0;
      font-size: 20px;
  }

  .span_header_sanitar {
      font-size: 14px;
  }
}


  @media screen and (max-width: 540px) {
    .sanitar {
      width: 100%;
      margin: auto;
      font-size: 12px;
  }

 
   
    .sanitar_cont {
      margin-left: 5%;
      margin-right: 5%;
    }
    
    .sanitar_box {
      grid-template-columns: 1fr 4fr 3fr;
      padding: 5px;
    }

  }

  @media screen and (max-width: 412px) {
    .sanitar {
      width: 300px;
    }
  .sanitar h4 {
    width: 20%;
  } 
}

  @media screen and (max-width: 360px) {
    .sanitar_box p {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 280px) {
    .cont_header_sanitar {
      background-size: 55%;
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%); 
      padding: 40px;
      }
   
    .sanitar_cont {
      margin-left: 0%;
      margin-right: 0%;
    }
    .sanitar_box {
      padding: 0px;
    }
  }

