.cont_header_meeting {
    background-color: #4281ab;
    background-image: url('../houses.png');
    background-position: right;
    background-size: 28%;
    background-repeat: no-repeat;
    padding: 100px;
    border: 1px solid #ffffff;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
    margin-bottom: 50px;
}

.main_header_meeting {
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    margin-left: 10%;
    font-size: 28px; 
}

.span_header_meeting {
    font-weight: 100;
    font-size: 16px;
}

.input_meeting {
padding: 10px;
cursor: auto;
font-family: "Roboto", sans-serif;
width: 300px;
margin-left: 10%;
margin-bottom: 20px;
border-radius: 5px;
border: 3px solid #ccc;
}

.input_meeting:hover {
border: 5px solid #efe74a;
}

.meeting_cont {
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .meeting_box {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 3fr 6fr;
    width: 100%;
    border-bottom: 1px solid #ccc; 
    cursor: pointer;
    text-align: center;
  }
  
  .meeting_box p,
  .meeting_box h4 {
    padding: 10px;
    font-family: "Roboto", sans-serif;
    color: #666666;
    font-size: 14px;
  }

  .meeting_box:nth-child(3n+1):hover {
    background-color: rgb(134, 199, 241, 0.7);
  }
  
  .meeting_box:nth-child(3n+2):hover {
    background-color: rgb(239, 231, 74, 0.7);
  }
  
  .meeting_box:nth-child(3n):hover {
    background-color: rgb(26, 158, 59, 0.5);
  }


  @media screen and (max-width: 1024px) {
    .cont_header_meeting {
        background-size: 35%;
        }
  }

  @media screen and (max-width: 855px) {
    .cont_header_meeting {
        background-size: 40%;
        }
}

@media screen and (max-width: 560px) {
  .cont_header_meeting {
  background-size: 45%;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
  padding: 60px;
  }

  .main_header_meeting {
      margin-left: 0;
      font-size: 20px;
  }

  .span_header_meeting {
      font-size: 14px;
  }
}

  @media screen and (max-width: 540px) {
  .meeting_cont {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}
.meeting_box {
  grid-template-columns: 50px 150px 80px 150px 200px;
  width: 630px;
}

.meeting_box p,
.meeting_box h4 {
  white-space: normal;
}
.meeting_cont {
  margin: 0;
}
  }

  @media screen and (max-width: 2800px) {
    .cont_header_meeting {
          background-size: 55%;
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%); 
          padding: 40px;
          } 

      .input_meeting {
      width: 200px;
      }
    }

  
  