
.header {
    display: flex;
    justify-content: space-around;
    margin-left: 10%;
    margin-right: 10%;
    font-family: "Roboto", sans-serif;
   align-items: center;
  }
  .logo {
    display: flex;
    text-decoration: none;
  }
  
  .logo_name {
    display: flex;
    flex-direction: column;
   justify-content: center;
  }
  .company_name {
    color: #1a9e3b;
    font-family: "Roboto", sans-serif; 
    font-weight: bold;
    margin: 0;
    margin-left: 5px;
    text-align: center;
    font-size: 14px;
    
  }
  .phone {
    font-weight: bolder;
    cursor: pointer;
    color: #666666;
    text-decoration: none;
  }
  
  .phone:hover {
    text-decoration: underline;
  }
  
  .text_header {
    font-size: 14px;
    color: #666666;
  }
  
  .header_btn {
    margin: 2px;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .header_btn:hover {
    transform: translateY(-7px);
  }

.buttons_above {
  display: flex;
}

.emergency_contacts_main {
  text-align: center;
}

 @media screen and (max-width: 1024px) {
    .logo img {
      width: 100px;
    }
    .text_header {
      font-size: 12px;
    }
    .header {
      margin-left: 5%;
      margin-right: 5%;
  }
  .header_btn img{
    width: 30px;
  }
}

@media screen and (max-width: 853px) {

.company_name {
  font-size: 12px;
  margin-left: 0px;
}
}

@media screen and (max-width: 560px) {
  .header {
    position: relative;
  }
  
  .logo,
  .buttons_above {
    position: absolute;
    top: 0;
    left: 5%;
  }
  
  .buttons_above {
    position: absolute;
    top: 10px;
    right: 15%;
    left: auto; 
    margin-left: 20px; 
  }
  
  .emergency_contacts_main {
    margin-top: 60px;
    margin-bottom: 5px; 
  }
  }

  @media screen and (max-width: 431px) {
    .logo img {
      width: 80px;
    }
    
    .buttons_above {
      top: 10px;
      right: 7%;
    }
    .logo {
      top: 5px;
    }
    }
  
    @media screen and (max-width: 280px) {
      .header {
        position: static;
        display: flex;
        flex-direction: column;
      }
      
      .logo,
      .buttons_above {
        position: static;
      }
      
      .buttons_above {
        margin-left: 0px; 
      }
      
      .emergency_contacts_main {
        margin-top: 5px;
        margin-bottom: 5px; 
      }
      }
    


