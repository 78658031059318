.cont_header_organisations {
    background-color: #4281ab;
    background-image: url('../houses.png');
    background-position: right;
    background-size: 28%;
    background-repeat: no-repeat;
    padding: 100px;
    border: 1px solid #ffffff;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
}

.main_header_organisations {
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    margin-left: 10%;
    font-size: 28px; 
    width: 50%;
}

.span_header_organisations {
    font-weight: 100;
    font-size: 16px;
}
.organisations {
    margin: 50px 10% 50px 10%;
}
.organisation_container {
    border: 1px solid black;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

  .organisation_container:nth-child(3n+1):hover {
    background-color: rgb(134, 199, 241, 0.7);
  }
  
  .organisation_container:nth-child(3n+2):hover {
    background-color: rgb(239, 231, 74, 0.7);
  }
  
  .organisation_container:nth-child(3n):hover {
    background-color: rgb(26, 158, 59, 0.5);
  }

.org_number {
    font-size: 40px;
    font-family: "Russo One", sans-serif;
    color: #1a9e3b;
    padding: 20px;
}

.organisation_box {
    padding: 20px;
}

.organisation_box h3 {
    font-family: "Russo One", sans-serif;
    color: #1a9e3b;
    padding: 5px;
}

.organisation_box p {
    font-family: "Roboto", sans-serif;
    padding-bottom: 5px;
    color: #666666;
}



@media screen and (max-width: 1024px) {
    .cont_header_organisations {
        background-size: 35%;
        }
  }

@media screen and (max-width: 855px) {
    .cont_header_organisations {
        background-size: 40%;
        }
}

@media screen and (max-width: 560px) {
    .cont_header_organisations {
    background-size: 45%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    padding: 60px;
    }

    .main_header_organisations {
        margin-left: 0;
        font-size: 20px;
    }

    .span_header_organisations {
        font-size: 14px;
    }
  }

  @media screen and (max-width: 415px) {
.org_number {
    font-size: 32px;
    padding: 10px;
}

.organisation_box h3 {
    font-size: 16px;
}
.organisation_box p {
    font-size: 14px;
}
.organisation_container {
    width: 320px;
}
  }

  @media screen and (max-width: 390px) {
    .organisation_container {
        width: 300px;
    }
      }

  @media screen and (max-width: 280px) {
    .cont_header_organisations {
        background-size: 55%;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%); 
        padding: 40px;
        }
        .org_number {
            font-size: 24px;
        }
        .organisations {
            margin: 50px 5% 50px 5%;
        }
        .organisation_container {
            width: 240px;
        }
  }

