



.header_main_page {
    color: #1a9e3b;
    font-family: "Russo One", sans-serif;
    margin: 50px 10% 50px 10%;
    font-size: 32px;
}



/* гос услуги */

#js-show-iframe-wrapper {
  margin-left: 13%;
}








#js-show-iframe-wrapper {
  background: linear-gradient(#2d73bc 26.49%, #38bafe 79.45%);
  grid-template-columns: 100%;
  grid-template-rows: 264px auto;
  max-width: 100%;
  text-font-size: 20px;
  text-small-font-size: 14px;
  text-margin: 0 0 12px 0;
  description-margin: 0 0 24px 0;
  button-wrap-max-width: 100%;
  background-image: url('https://pos.gosuslugi.ru/bin/banner-fluid/35/banner-fluid-bg-35.svg');
  background-position: right bottom;
  content-padding: 26px 24px 20px;
  content-grid-row: 0;
  logo-wrap-padding: 16px 12px 12px;
  logo-width: 65px;
  logo-wrap-top: 0;
  slogan-font-size: 12px;
}

#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2 {
  width: 100%;
  min-height: 52px;
  background: #fff;
  color: #0b1f33;
  font-size: 16px;
  font-family: LatoWeb, sans-serif;
  font-weight: 400;
  padding: 0;
  line-height: 1.2;
}

#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2:active,
#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2:focus,
#js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2:hover {
  background: #e4ecfd;
}

@media screen and (min-width: 340px) {
  #js-show-iframe-wrapper .pos-banner-fluid .pos-banner-btn_2 {
    max-width: 209px;
  }
}

@media screen and (min-width: 360px) {
  #js-show-iframe-wrapper {
    background-image: url('https://pos.gosuslugi.ru/bin/banner-fluid/35/banner-fluid-bg-35-2.svg');
    background-position: calc(100% + 135px) bottom;
  }
}

@media screen and (min-width: 482px) {
  #js-show-iframe-wrapper {
    text-font-size: 23px;
    text-small-font-size: 18px;
    background-position: center bottom;
  }
}

@media screen and (min-width: 568px) {
  #js-show-iframe-wrapper {
    background-image: url('https://pos.gosuslugi.ru/bin/banner-fluid/35/banner-fluid-bg-35.svg');
    background-position: calc(100% + 35px) bottom;
    text-font-size: 24px;
    text-small-font-size: 14px;
    grid-template-columns: 1fr 292px;
    grid-template-rows: 100%;
    content-grid-row: 1;
    content-padding: 48px 24px;
  }
}








@media screen and (max-width: 391px) {
.header_main_page {
font-size: 24px;
margin: 50px 10% 20px 10%;
}

}

