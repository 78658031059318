
.header_about {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Roboto", sans-serif;
    color: #666666;
    margin-top: 50px;
  }
  .heading_about {
    font-size: 36px;
  }
  .header_text_about {
    font-style: italic;
    margin: 0;
  }

  .container_about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  }
  
  .text_about {
    width: 400px;
    height: 220px;
    border: none;
    background-color: rgb(244, 243, 247, 0.7);
    text-align: center;
    padding: 20px;
    margin-right:-20px;
    margin-left:-20px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    align-self: center;
    font-family: "Roboto", sans-serif;
    color: #666666;
  }

  .text_about_0:hover {
    background-color: rgb(134, 199, 241, 0.7);
  }
  
  .text_about_1:hover {
    background-color: rgb(239, 231, 74, 0.7);
  }
  
  .text_about_2:hover {
    background-color: rgb(26, 158, 59, 0.7);
  }
  
  .description_about {
   margin-top: 30px;
  }
  
  .block_about {
    display: flex;
    margin: 40px;
    margin-right: 200px;
  }  
  
.img_about {
  z-index: -1;
}

.block_about_right {
  margin-left: 250px;
}

@media screen and (max-width: 1024px) {
  .img_about {
    width: 420px;
  }

  .text_about {
    width: 400px;
    height: 200px;
  }

  .block_about {
    margin-right: 150px;
  }  
}

@media screen and (max-width: 912px) {
  .img_about {
    width: 350px;
  }

  .text_about {
    width: 320px;
    height: 180px;
  }

  .description_about {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .img_about {
    width: 310px;
  }

  .text_about {
    width: 370px;
    height: 150px;
  }
  .block_about {
    margin-right: 50px;
  }  
  .block_about_right {
    margin-left: 50px;
  }
}

@media screen and (max-width: 540px) {
  .heading_about {
    font-size: 32px;
  }

  .block_about {
    flex-direction: column;
  }  
  .img_about {
    width: 370px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  .text_about {
    width: 370px;
    padding: 0;
    height: 200px;
  }
  .text_about h3 {
    margin-top: 10px;
  }
.description_about {
  padding: 10px;
}
  .block_about_right {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 540px) {
  .heading_about {
    font-size: 24px;
  }
}

@media screen and (max-width: 376px) {
  .heading_about {
    font-size: 20px;
  }
  .header_text_about {
    font-size: 14px;
  }
  .img_about {
    width: 300px;
  }
  .text_about {
    width: 300px;
    height: 240px;
  }
}

@media screen and (max-width: 280px) {
  .heading_about {
    text-align: center;
  }
  .header_text_about {
    text-align: center;
  }
  .img_about {
    width: 250px;
  }
  .text_about {
    width: 250px;
    height: 280px;
  }
}