.cont_header_finance {
    background-color: #4281ab;
    background-image: url('../houses.png');
    background-position: right;
    background-size: 28%;
    background-repeat: no-repeat;
    padding: 100px;
    border: 1px solid #ffffff;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%); /* Определение трапеции с помощью polygon */
   }
   
   
   .main_header_finance {
       font-family: "Roboto", sans-serif;
       color: #ffffff;
       margin-left: 10%;
       font-size: 28px;
   }

   .span_header {
    font-weight: 100;
    font-size: 16px;
}

.finance_buttons {
  display: flex;
}

.finance_btn {
    flex: 1;
    background-color: transparent;
    color: #1a9e3b;
    border-radius: 15px;
    padding: 5px;
    margin: 5px;
    text-transform: uppercase;
    border: 3px #1a9e3b solid;
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
    text-decoration: none;
    font-size: 14px; 
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

}

.finance_btn:hover {
    font-weight: bold;
    text-decoration: underline;
    background-color: #0d7727;
    color: #ffffff;
}

.box_finance  {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px; 
    margin: 50px 10% 50px 10%;
}

.finance_year {
    font-family: "Russo One", sans-serif;
    color:#1a9e3b;
    font-size: 24px;
}

.content_finance {
    text-align: center;
    padding: 20px;
    border: none;
    background-color: rgb(244, 243, 247, 0.7);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif;
    color: #666666;
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0 auto;
}

  .content_finance:nth-child(3n+1):hover {
    background-color: rgb(134, 199, 241, 0.7);
  }
  
  .content_finance:nth-child(3n+2):hover {
    background-color: rgb(239, 231, 74, 0.7);
  }
  
  .content_finance:nth-child(3n):hover {
    background-color: rgb(26, 158, 59, 0.5);
  }



  @media screen and (max-width: 1280px) {
    .box_finance  {
      grid-template-columns: 1fr 1fr;
  }
  }

  @media screen and (max-width: 1024px) {
    .cont_header_finance {
        background-size: 35%;
        }
  }

  @media screen and (max-width: 855px) {
    .box_finance  {
      margin: 50px 5% 50px 5%;
  }
    .cont_header_finance {
        background-size: 40%;
        }
  }

  @media screen and (max-width: 768px) {
    .box_finance  {
      grid-template-columns: 1fr;
  }
  }

  @media screen and (max-width: 560px) {
    .cont_header_finance {
    background-size: 45%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    padding: 60px;
    }
    .main_header_finance {
      margin-left: 0;
      font-size: 20px;
  }

  .span_header {
      font-size: 14px;
  }
}

  @media screen and (max-width: 360px) {
    .content_finance {
      padding: 5px;
      width: 240px;
      height: 230px;
  }
  .finance_buttons {
   flex-direction: column;
   width: 80%;
  }
  }

  @media screen and (max-width: 280px) {
    .cont_header_finance {
        background-size: 55%;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%); 
        padding: 40px;
        }
  }



