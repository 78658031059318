.cont_header_services {
    background-color: #4281ab;
    background-image: url('../houses.png');
    background-position: right;
    background-size: 28%;
    background-repeat: no-repeat;
    padding: 100px;
    border: 1px solid #ffffff;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
    margin-bottom: 50px;
}

.main_header_services {
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    margin-left: 10%;
    font-size: 28px; 
}

.span_header_services {
    font-weight: 100;
    font-size: 16px;
}

@media screen and (max-width: 1024px) {
    .cont_header_services {
        background-size: 35%;
        }
  }

@media screen and (max-width: 855px) {
    .cont_header_services {
        background-size: 40%;
        }
}

@media screen and (max-width: 560px) {
    .cont_header_services {
    background-size: 45%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    padding: 60px;
    }

    .main_header_services {
        margin-left: 0;
        font-size: 20px;
    }

    .span_header_services {
        font-size: 14px;
    }
  }

  @media screen and (max-width: 280px) {
    .cont_header_services {
        background-size: 55%;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%); 
        padding: 40px;
        }
  }

