.cont_header_documents {
 background-color: #4281ab;
 background-image: url('../houses.png');
 background-position: right;
 background-size: 28%;
 background-repeat: no-repeat;
 padding: 100px;
 border: 1px solid #ffffff;
 clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%); 
}


.main_header_documents {
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    margin-left: 10%;
    font-size: 28px;
   
}

.span_header {
    font-weight: 100;
    font-size: 16px;
}

.cont_doocuments {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.box_documents {
    border: none;
    background-color: rgb(244, 243, 247, 0.7);
    text-align: center;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif;
    color: #666666;
    padding: 10px;
    text-align: center;
    width: 300px;
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.documents_btn {
    display: inline-block;
    position: relative;
    background-color: #1a9e3b;
    color: #ffffff;
    border-radius: 15px;
    padding: 15px;
    text-transform: uppercase;
    border: none;
    font-family: "Roboto", sans-serif; 
    cursor: pointer;
    text-decoration: none;
    font-size: 14px; 
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

}

.documents_btn:hover {
    font-weight: bold;
    text-decoration: underline;
    background-color: #0d7727;
}

.box_taxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 100px;
}

.box_content {
    border: none;
    background-color: rgb(244, 243, 247, 0.7);
    text-align: center;
    padding: 20px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Roboto", sans-serif;
    color: #666666;
    width: 300px;
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.heading_documents {
    text-align: center;
    font-family: "Russo One", sans-serif;
    color: #1a9e3b;
    margin-top: 100px;
    margin-bottom: 50px;
}


@media screen and (max-width: 1024px) {
    .cont_header_documents {
        background-size: 35%;
    }

    .box_content {
        width: 270px;
    }
  }

  @media screen and (max-width: 912px) {
    .box_content {
        margin: 10px;
    }
  }

  @media screen and (max-width: 855px) {
    .cont_header_documentss {
        background-size: 40%;
        }
}

@media screen and (max-width: 560px) {
    .cont_header_documents {
    background-size: 45%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
    padding: 60px;
    }

    .main_header_documents {
        margin-left: 0;
        font-size: 20px;
    }

    .span_header {
        font-size: 14px;
    }
  }

  @media screen and (max-width: 281px) {
    .cont_header_documents {
        background-size: 55%;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%); 
        padding: 40px;
    }
    .cont_doocuments {
        margin-left: 3%; 
        justify-content: center;
    }
    .box_documents {
         width: 240px;
        
    }
    .heading_documents {
        font-size: 16px;
        margin-left: 10%;
        margin-right: 10%;
    }
    .box_content {
        width: 240px;
        height: 400px;
    }
  }



  
